.slider{
  margin: 20px 0;
  height: 10px;
}

.slider .track{
  height: 10px;
}

.slider .track.track-0, table.splitTable td.track-0{
  background: #3791ff;
}


.slider .track.track-1, table.splitTable td.track-1{
  background: #ff7279;
}

.slider .track.track-2, table.splitTable td.track-2{
  background: #c9ff90;
}

.slider .track.track-3, table.splitTable td.track-3{
  background: #ff63c8;
}

.slider .track.track-4, table.splitTable td.track-4{
  background: #85fff3;
}

.slider .track.track-5, table.splitTable td.track-5{
  background: #bb93ff;
}



.slider .thumb{
  height: 30px;
  margin-top: -10px;
  background: #fcfcfc;
  border: 1px solid #ccc;
  width: 10px;
  cursor: pointer;
}

table.splitTable{
  border: 1px solid #ccc;
  width: 300px;
}

table.splitTable > thead > tr > th:first-child{
  width: 200px;
}

table.splitTable td, table.splitTable th{
  padding: 5px;
  border: 1px solid #ccc;
}