


.RouteContainer-enter {
  opacity: 0.01;
}

.RouteContainer-enter.RouteContainer-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.RouteContainer-leave {
  opacity: 1;
}

.RouteContainer-leave.RouteContainer-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.navbar-header{
  float: left;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

@media (max-width: 500px){
  .navbar-header{
    display: none;
  }
}

.navbar-nav {
  float: left;
  margin: 0;
}
.navbar-right{
  float: right !important;
  margin-right: -15px !important;
}
.navbar-nav > li {
  float: left;
}
.navbar-nav > li > a {
  padding-top: 14.5px;
  padding-bottom: 14.5px;
}
