.syntax-highlighter-with-copy pre > code:before{
  width: 60px;
  height: 12px;
  content: "";
  float: right;
}

.syntax-highlighter-with-copy pre{
  font-size: 11px;
  letter-spacing: -.3px;
}

.syntax-highlighter-with-copy{
  position: relative;
}
