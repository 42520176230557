.password-strength-bar ul{
  display:inline;
  list-style:none;
  margin:0;
  margin-left:15px;
  padding:0;
  vertical-align:2px;
}

.password-strength-bar ul .point:last {
  margin:0 !important;
}
.password-strength-bar ul .point {
  background:#DDD;
  border-radius:2px;
  display:inline-block;
  height:5px;
  margin-right:1px;
  width:20px;
}