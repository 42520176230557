.label-primary a {
    color: #ffffff;
}
.version {
    position: absolute;
    bottom: 2px;
    left: 2px;
    font-size:8pt
}

.link-button {
    background-color: transparent;
    color: #2780e3;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;
}

.link-button:hover,
.link-button:focus {
    text-decoration: underline;
}

.btn-light {
    color: rgb(66, 82, 110);
    background: none;
}

.btn-light:focus {
    color: rgb(66, 82, 110) !important;
    background-color: rgba(9, 30, 66, 0.08);
}
.btn-light:hover {
    color: rgb(66, 82, 110) !important;
    background-color: rgba(9, 30, 66, 0.08);
}

////LoadingOverlay

#loading-overlay {
    background: #ffffff;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    top: 50px;
    float: left;
    text-align: center;
    padding-top: 25%;
    opacity: .50;
    &.fadeOut{
        opacity:0;
        width:0;
        height:0;
        transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
   
   }
//    &.fadeIn{
//         opacity:1;
//         width:100px;
//         height:100px;
//         transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;   
//     }    
    .spinner {
        margin: 0 auto;
        height: 48px;
        width: 48px;
        //animation: rotate 0.8s infinite linear;
        border: 5px solid rgb(164, 214, 200);
        //border-right-color: transparent;
        border-radius: 50%;
    }
    .spinner-middle {
        margin: 1px;
        height: 36px;
        width: 36px;
        animation: rotate-inner 1.5s infinite linear;
        border: 5px solid rgb(4%, 17%, 28%);
        border-left-color: transparent;
        border-radius: 50%;
    }
    .spinner-inner {
        margin: 1px;
        height: 24px;
        width: 24px;
        animation: rotate-inner 1.2s infinite linear;
        border: 5px solid rgb(248, 178, 28);
        border-left-color: transparent;
        border-bottom-color: transparent;
        border-radius: 50%;
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes rotate-middle {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes rotate-inner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
////END LoadingOverlay

.scrollable-navmenu > ul {
    height: auto;
    max-height: 600px;
    overflow-x: hidden;
}