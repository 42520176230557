#login-container{
  height: 100%;
  display: flex;
}

#login {
  position: relative;
  flex: 1;
  flex-direction: row;
  overflow: hidden;
  background: url(../assets/images/backgrounds/ff-login-background.png) no-repeat;
  background-size: cover;
}

#login > img{
  position: absolute;
  top: 60px;
  left: 5%;
  width: 20%;
  max-width: 150px;
}

#login > div{
  position: absolute;
  top: 60px;
  right: 80px;
  width: 50%;
}

#sidebar{
  width: 400px;
  background: white;
  position: relative;
  box-shadow:
      0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 30px;
}

#sidebar > *:nth-child(1){
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
}

#sidebar >  *:nth-child(2){
  display: block;
  flex-grow: 4;
  margin-top: 30px;
}
