#activate-container{
  height: 100%;
  display: flex;
}

#activate {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: url(../assets/images/backgrounds/ff-login-background.png) no-repeat;
  background-size: cover;
}

#activate #activate-box{
  width: 660px;
  height: 550px;
  background: white;
  padding: 10px;
  box-shadow: 2px 2px 10px rgba(0,0,0, 0.2);
}