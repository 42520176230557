body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.dropdown-menu > li > a > svg {
  margin: 3px 6px 3px 0;
}

html, body, #root{
  height: 100%;
}

.loading-page{
  background: #444;
  text-align: center;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading-page .logo{
  animation: breathing 20s ease-out infinite normal;
}

.loading-text{
  color: white;
  font-size: 40px;
  font-weight: 100;
}
.right-most-column-full-width tr th:first-child{
  white-space: nowrap;
}
.right-most-column-full-width tr td:last-child{
  width: 100%;
}


.focus-input-filter-width-grow{
  width: 150px !important;
  max-width: 100% !important;
  transition: .3s ease-in-out width;
}

.focus-input-filter-width-grow:focus{
  width: 350px !important;
  outline: none !important;
}

.opacity-hover-background > a:hover,
.opacity-hover-background > a:focus,
.opacity-hover-background.open > a{
  background: rgba(128,128,128,0.2) !important;
}


@keyframes breathing {
  0% {
    transform: scale(0.9);
  }

  25% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(0.9);
  }
}

.pointer{
  cursor: pointer;
}


.fa-spin {
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.Select-control{
  border-radius: 0;
}

.form-group-sm .Select-control{
  font-size: 13px;
  height: 28px;
}

.form-group-sm .Select-control .Select-placeholder,
.form-group-sm .Select-control .Select-input{
  line-height: 28px;
  height: 28px;
}

.form-group-sm .Select-placeholder, .Select--single > .Select-control .Select-value{
  line-height: 28px;
  height: 28px;
}

.form-group-sm .Select--multi .Select-value{
  margin-top: 3px;
  margin-left: 3px;
}


.form-group.has-error .Select-control{
  border-color: #ff0039;
}


.Select-option-group-label {
  box-sizing: border-box;
  background-color: #fff;
  color: #666666;
  font-weight: bold;
  cursor: default;
  display: block;
  padding: 8px 10px;
}
.Select-option-group-label ~ .Select-option,
.Select-option-group-label ~ .Select-option-group {
  padding-left: 20px;
}

@media (min-width: 1200px){
  .modal-lg {
    width: 1100px;
  }
}

.Select .Select-menu-outer{
  z-index: 2000;
}

.percentInput{
  position: relative;
}

.percentInput input{
  text-align: right;
  padding-right: 16px;
  width: 50px;
}

.percentInput:after{
  content: '%';
  position: absolute;
  right: 10px;
  top: 0;
  line-height: 38px;
  z-index: 1000;
  pointer-events: none;
}

.stat-panel{
  padding: 8px;
  &.stat-panel-primary{
    background: #acb5ff;
  }
  &.stat-panel-secondary{
    background: yellow;
  }
}
